import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { StateService, UIRouter } from '@uirouter/angular';

import { ListOfEntrantsComponent } from '../list-of-entrants/list-of-entrants.component';

import { PricePayoutComponent } from '../price-payout/price-payout.component';


// data table


// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

import {SERVER_NAME, APIS} from '../config';


@Component({
  selector: 'app-contest-listing',
  templateUrl: './contest-listing.component.html',
  styleUrls: ['./contest-listing.component.scss'],
  providers: [UserDetailsService, HttpService]
})

export class ContestListingComponent implements OnInit {
  contestListData: any;
  currentPage: number = 1;
  collection: any[] = [];  
  numberOfItem: number;
  totalPages: number;
  itemsPerPage: number= 50;
  contestId: any;
  currentDate = new Date();

  constructor(
    private _http: Http, 
    private _userDetailsService: UserDetailsService,
    private _httpService: HttpService,
    private _uiRouter: UIRouter
    ) 
  { 
      
  }

  ngOnInit() {
    console.log('get header call',this._userDetailsService.getHeader());
    // console.log('SERVER_NAME',SERVER_NAME);
    window.localStorage.removeItem("teamid");
    window.localStorage.removeItem("teamName");
    window.localStorage.removeItem("playersData");
    this.contestListingData();
    // console.log('HttpService', this._httpService.getApiUrl('contestListing'));
  }

  contestListingData(){
    let listingData: any = {
            "leagueType":null,
            "contestType":null,
            "featured":"0",
            "currentPage" :this.currentPage,
            "currentSize": this.itemsPerPage
    }

      this._httpService.httpRequestForMicroServices('POST', 'contestListing', 'contests/getUpcomingContests', listingData)
    // this._httpService.httpRequest2('POST','contestListing',listingData, false)
    // this._httpService.httpRequest('POST','contestListingJava',listingData, false)
    .then(Response => {
      let data: any = Response['response'].data;
      this.numberOfItem = Response['response'].pagination.numberOfElements;
      this.totalPages = Response['response'].pagination.totalPages;
      this.collection = data;
      

    })
    .catch(data => {

    })
  }

  pageChanged($event){
    console.log("$event", $event);
    this.currentPage = $event;
    this.contestListingData();
  }

  contestlistClickFun(contestVal){
    this.contestId = contestVal.contestId;
  }

  toggleFeatured($event,contestId){
      console.log("$event", $event);
      let featured;
      if($event){
        featured = 1;
      }else{
        featured = 0;
      }

      this._httpService.httpRequest('POST','featuredUnfeatured',{"contestId":contestId,"featured":featured}, true)
      .then(Response => {
        let responseData: any = Response;
        this._userDetailsService.showSuccess(responseData.message);
      })
      .catch(data => {

      })
  }


  listOfEntrantsFun(contestId){
    console.log('contest id', contestId);
    this._uiRouter.stateService.go('list-of-entrants',{'contestId': contestId,'leaugeStatus':'upcoming'});
  }


  cancelContest(contestId){
      var txt;
      var r = confirm("Are you sure you want to cancel this contest?\n*On OK, contest will be removed from being diplayed on Mobile App.");
      if (r == true) {
          this._httpService.httpRequest('GET','cancelContest',"contestid="+contestId, true)
            .then(Response => {
              let responseData: any = Response;
              this._userDetailsService.showSuccess(responseData.message);
               this._uiRouter.stateService.reload();
            })
            .catch(data => {
              this._userDetailsService.showError(data.message);
          })
      } else {
         
      }
      
  }


  deleteContest(contestId){
      var txt;
      var r = confirm("Are you sure you want to delete this contest?");
      if (r == true) {
          this._httpService.httpRequest('GET','cancelContest',"contestid="+contestId, true)
            .then(Response => {
              let responseData: any = Response;
              this._userDetailsService.showSuccess(responseData.message);
               this._uiRouter.stateService.reload();
            })
            .catch(data => {
              this._userDetailsService.showError(data.message);
          })
      } else {
         
      }
  }


  viewDetailFun(contestId){

    console.log('hi',contestId);
    this._uiRouter.stateService.go('contest-detail',{contestId: contestId});
  }

  edit(contestId){
    console.log('contest id', contestId);
    this._uiRouter.stateService.go('edit-contest',{"contestId": contestId,"leaugeStatus":"upcoming"});
  }

  createSimilar(similarContestId){
    this._uiRouter.stateService.go('create-similar',{"similarContestId": similarContestId});
  }

  propCheckTraditionalFun(firstPrizePayout, time, type, id){
    let traditionalRequestObj = {
      "startTime": time,
      "leagueType": type,
      "adminContestId": id
    }
    console.log('traditionalRequestObj',traditionalRequestObj);
    if(firstPrizePayout == null){
      console.log('traditional pricepayout');
      this._uiRouter.stateService.go('PricePayoutComponent');
      
    }else{
      console.log('traditional null');
      this._uiRouter.stateService.go('TeamListingComponent',traditionalRequestObj);
    } 
  }

  propCheckVersusFun(firstPrizePayout){
    if(firstPrizePayout == null){
      console.log('versus pricepayout');
      this._uiRouter.stateService.go('PricePayoutComponent');
    }else{
      console.log('versus null');
      this._uiRouter.stateService.go('TeamListingVersusComponent');
    } 
  }


  
}

import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';
// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

// component
import { ListOfEntrantsComponent } from '../list-of-entrants/list-of-entrants.component';
import{ ContestDetailComponent } from '../contest-detail/contest-detail.component';

import {SERVER_NAME, APIS} from '../config';

declare const moment:any


@Component({
  selector: 'app-contest-listing-history',
  templateUrl: './contest-listing-history.component.html',
  styleUrls: ['./contest-listing-history.component.scss'],
  providers: [UserDetailsService, HttpService]
})

export class ContestListingHistoryComponent implements OnInit {
  filterfrm: FormGroup;
  searchfrm : FormGroup;
  contestListData: any;
  currentPage: number = 1;
  collection: any[] = [];  
  numberOfItem: number;
  totalPages: number;
  itemsPerPage: number= 10;
  contestId: any;
  currentDate = new Date();
  contestData : any =[];
  arrayOfKeyValues : any=[];
  showErrorMsg :boolean =  false;

  dateFrom: Date = new Date();
  datepickerOpts = {
      endDate:this.dateFrom,
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }

  
  constructor(
    private _http: Http, 
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private fb: FormBuilder,
    private _uiRouter: UIRouter
    ) 
  { 
      
  }

  ngOnInit() {

    
    console.log('get header call',this._userDetails.getHeader());
    console.log('SERVER_NAME',SERVER_NAME);

    this.filterfrm = this.fb.group({
      leagueType: ['ALL', []],
      contestType: ['ALL', []],
      sortBy:['',[]],
      contestDate:['',[]]
    })

    this.searchfrm = this.fb.group({
      contestName: ['', []],
    })

    // window.localStorage.removeItem("teamid");
    // window.localStorage.removeItem("teamName");
    // window.localStorage.removeItem("playersData");
    this.contestListingData();
    // console.log('HttpService', this._httpService.getApiUrl('contestListing'));
  }

  contestListingData(sortBy='sortByContestDate',leagueType='ALL',contestType='ALL',contestDate=''){
    
    console.log('schwifty');
    let listingData: any = {
            "leagueType":leagueType,
            "contestType":contestType,
            "contestDate":contestDate?moment(contestDate).format('YYYY-MM-DD'):'',
            "currentPage" :this.currentPage,
            "currentSize": this.itemsPerPage,
            "sortByContestDate":sortBy=="sortByContestDate"?true:false,
            "sortByEntryFee":sortBy=="sortByEntryFee"?true:false,
            "sortByGuaranteedPrizeMoney":sortBy=="sortByGuaranteedPrizeMoney"?true:false,
            "sortByTotalparticipents":sortBy=="sortByTotalparticipents"?true:false,
            "sortByNoOfPlacesPaidPrizeMoney":sortBy=="sortByNoOfPlacesPaidPrizeMoney"?true:false,
    }


    this._httpService.httpRequest('POST','historyContestListing',listingData, true)
    .then(Response => {
      let data: any = Response['response'].data;
      this.numberOfItem = Response['response'].pagination.numberOfElements;
      this.totalPages = Response['response'].pagination.totalPages;
      this.collection = data;
      console.log('collection',this.collection);

    })
    .catch(data => {

    })
  }

  pageChanged($event,val){
    console.log(val);
    this.currentPage = $event;
    this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.contestDate);
  }

  contestlistClickFun(contestVal){
    this.contestId = contestVal.contestId;
  }

  sortby($event){
    console.log($event);
    this.contestListingData($event);
  }

  filterData(val){
    //console.log();
    console.log(val);
    this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.contestDate);
  }

  entrantsListFun(contestId){
    this._uiRouter.stateService.go('list-of-entrants',{'contestId':contestId,'leaugeStatus':'history'});
  }


  getSearchData(searchText){
    searchText = $.trim(searchText);
    if(searchText.length<1){
      $(".searchList ul").hide();
    }
    if(searchText.length>2){
        
        this._httpService.httpRequest('GET','searchByContestName',"contestName="+searchText, false)
        .then(Response => {
          let responseData: any = Response;
          this.contestData = responseData.response;
          $(".searchList ul").show();
          this.showErrorMsg = true;
          //console.log('contestData',this.contestData);
        })
        .catch(data => {
           this.showErrorMsg = false;
        })
    }else{
      this.showErrorMsg = false;
    }
  }

  gotodetails(cid){
      $(".searchList ul").hide();
      this._uiRouter.stateService.go('contest-detail',{'contestId':cid});
  }

  resetFilter(){
    this.contestListingData();
  }

  viewDetailFun(contestId){
    console.log('hi',contestId);
    this._uiRouter.stateService.go('contest-detail',{contestId: contestId});
  }

  exportCSV(){
      this._httpService.httpRequest('GET','getCSV','', false)
        .then(Response => {
          //let responseData: any = Response;
        })
        .catch(data => {
      
        })
  }

}

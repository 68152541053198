import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpService } from '../services/http.service';
import { UIRouter } from '@uirouter/angular';


import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { UserDetailsService } from '../services/user-details.service';

@Component({
  selector: 'app-props-modal-single',
  templateUrl: './props-modal-single.component.html',
  styleUrls: ['./props-modal-single.component.scss']
})
export class PropsModalSingleComponent implements OnInit {
@ViewChild('propModal') propModalSingle: ModalDirective;
  propsPostData :any =  {};
  eventPropsdata : any;
  propsData: any;
  selectedPlayer = [];
  addPropForm: FormGroup;
  //checkedProps = [];
  propObj: any;
  playerData = [];
  adminPropParameters=[];
  formatError: string;
  //adminContestId : any;
  //teamid : any;
  playerName : string;
  floatError : string;
  maxLimitError : string;
  inputChecked: any = false;
  leagueType:any;
  underValue:number;
  overValueError:string;
  halfValue:number;
  isSecondHalf:boolean;
  firstHalfStats:number;
  isPromo:boolean;


  constructor(private _httpService:HttpService, 
  private fb: FormBuilder,
  private _userDetailsService : UserDetailsService,
  private _uiRouter:UIRouter) { }

  ngOnInit() {
    this.leagueType = this._uiRouter.stateService.params.leagueType;
    console.log(this._uiRouter.stateService.params.leagueType);
    
    this._httpService.httpRequest('GET', 'propParameter',"leagueType="+this.leagueType, true)
      .then(Response => {
        let propsData :any = Response;
        this.propsData = propsData.response;
        console.log("propsData======"+this.propsData);
        
      })
      .catch(data => {
        console.log('props parameters error', data);
    })

    this.addPropForm = this.fb.group({
      propValue : ['', []],
      overValue : ['', []],
      underValue : ['', []],
      halfValue : ['', []],
      firstHalfStats:['',[]],
      isPromo: ['',[]]
    })

  }

  show(player, eventPropsdata, adminContestId, teamid, leagueType) {
    console.log("GET SCHwIFTY");
    console.log('modal player data', player, eventPropsdata);
    this.eventPropsdata = eventPropsdata;
    player.contestTradational = true;
    player.teamId = teamid;
    player.adminContestId = adminContestId;
    player.statPlayerId = player.playerId;
    this.playerData = player;
    this.playerName = player.firstName + " " + player.lastName;
    this.leagueType = leagueType;
    this.propModalSingle.show();
  }

  closeModal(){
    this.propModalSingle.hide();
  }

  selectPlayer(playerId){
   this.selectedPlayer.push(playerId);
  }

  savePlayerProps(val){
    console.log('event model object ',this.eventPropsdata);
    this.propsPostData = this.playerData;
    this.propsPostData['prop']={"propValue":val.propValue,"overPoints":val.overValue,"underPoints":val.underValue, "half":val.halfValue, "isPromo":val.isPromo ? val.isPromo : false};
    this.propsPostData['prop']['adminPropParameters']=this.adminPropParameters;
    this.propsPostData['event']=this.eventPropsdata;
    this.propsPostData['leagueType']=this.leagueType;

    
    console.log(this.propsPostData)
    if (document.activeElement.getAttribute("Name") === "Submit Single"){
      this._httpService.httpRequest('POST', 'propCreate',this.propsPostData, true)
      .then(Response => {
        console.log('create constest success', Response);
        let responseData :any = Response;
        if(responseData.success){
          this._userDetailsService.showSuccess(responseData.message);
          delete this.adminPropParameters;
          this.propModalSingle.hide();
          this._uiRouter.stateService.reload();
        }
      })
      .catch(data => {
        console.log('create contest error', data);
        this._userDetailsService.showError(data.message);
      })
    }else if (document.activeElement.getAttribute("Name") === "Submit House"){
      this._httpService.httpRequest('POST', 'createHouseProp',this.propsPostData, true)
      .then(Response => {
        console.log('create constest success', Response);
        let responseData :any = Response;
        if(responseData.success){
          this._userDetailsService.showSuccess(responseData.message);
          delete this.adminPropParameters;
          this.propModalSingle.hide();
          this._uiRouter.stateService.reload();
        }
      })
      .catch(data => {
        console.log('create contest error', data);
        this._userDetailsService.showError(data.message);
      })
    }
  }

  propCheckFun(props, e){


      if(e.target.checked){
        if(this.adminPropParameters.length >= 3){
          (e.target || e.srcElement).checked = false;
          this._userDetailsService.showError("You can select maximum of 3 props.");
        }else{
          console.log("test: " + JSON.stringify(props, null, 4))
          this.adminPropParameters.push({
            "propParamId": props.propParamId,
            "name": props.name
          });
        }
      }else{
          let indexof = this.adminPropParameters.findIndex(function(item, i){
            return item.propParamId == props.propParamId
          });
        this.adminPropParameters.splice(indexof,1);
      }
      console.log(JSON.stringify(this.adminPropParameters)); 
    

    /*let propName: any = props.name;
    this.inputChecked = document.querySelectorAll('input[type="checkbox"]:checked').length;
    
    if(this.inputChecked > 3){
      (e.target || e.srcElement).checked = false;
      this.maxLimitError = "You can select maximum of 3 props.";
    }else{
        this.maxLimitError = "";
        if(e.target.checked){
         this.adminPropParameters.push({
            "propParamId": props.propParamId    
          });
          console.log(this.adminPropParameters);
        }else{
          //delete this.adminPropParameters.propParamId;
          let indexof = this.adminPropParameters.findIndex(function(item, i){
              return item.propParamId === props.propParamId
            });
          this.adminPropParameters.splice(indexof,1);
          console.log(this.adminPropParameters);
        } 
    }*/
  }

  checkFloatValue(value){
    console.log('event',event);
    console.log('prop value', value);
    let checkDots = (value.match(/\./g) || []).length; 
    console.log('checkdots', checkDots);
    if($.isNumeric(value)){
      this.formatError = "";
      console.log('is numeric');
      if(value<0.5){
        this.floatError = "Prop value should be greater than or equal to 0.5";
        // return;
      }
      // else if(checkDots > 1){
      //   console.log('greater than 1');
        
      //   // return;
      // }
      else{
        var str = value.toString();
        if(!str.match(/^-?[0-9]*[.][5]{1}$/)) {
            this.floatError = "Prop value should be appended with (.5)";
            return;
        }else{
          this.floatError = "";
          this.formatError = "";
        }
      }
      return value;

    }else{
      if(value != ""){
        this.floatError = "";
        this.formatError = "Invalid format";
      }else{
        this.floatError = "";
        this.formatError = "";
      }
    }
    // console.log(value);
    // var str = value.toString();
    // if(!str.match(/^-?[0-9]*[.][5]+$/)) {
    //     this.floatError = "Prop value should be a float value(Eg. 1.5)";
    //     return;
    // }else{
    //   this.floatError = "";
    // }
    // return value;
  }



  checkSecondHalf(){
    this.isSecondHalf = (this.halfValue == 2);
    return this.isSecondHalf;
  }


  getUnderValue(val){
    var str = val.toString();
    
   /*if(val>0 && val<200){
        if(str.match(/^-?[0-9]+$/)) {
          this.overValueError = "";
          this.underValue = 200-val;
        }else{
          this.underValue = 0;
          this.overValueError = "Decimal is not allowed";
        }
      }else{
        this.underValue = 0;
        this.overValueError = "Over value must be greater than 0 and less than 200.";
      }*/
  }

}

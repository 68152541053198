import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { Moment } from 'moment';

// service
import { UserDetailsService } from '../services/user-details.service';
import { HttpService } from '../services/http.service';

// component
import { ListOfEntrantsComponent } from '../list-of-entrants/list-of-entrants.component';
import{ ContestDetailComponent } from '../contest-detail/contest-detail.component';

import {SERVER_NAME, APIS} from '../config';

declare const moment:any


@Component({
  selector: 'app-contest-players',
  templateUrl: './contest-players.component.html',
  styleUrls: ['./contest-players.component.scss'],
  providers: [UserDetailsService, HttpService]
})

export class ContestPlayersComponent implements OnInit {
  filterfrm: FormGroup;
  searchfrm : FormGroup;
  contestListData: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  collection: any[] = [];  
  numberOfItem: number;
  totalPages: number;
  contestId: any;
  currentDate = new Date();
  contestData : any =[];
  arrayOfKeyValues : any=[];
  showErrorMsg :boolean =  false;
  sortBy: any =0;
  dateSelectError : any = "";

  //dateFrom: Date = new Date();


  date1: Date;
  datepickerOpts = {
      endDate : new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }
  date2: Date ;
  datepickerToOpts = {
      startDate: this.date1,
      endDate : new Date(),
      autoclose: true,
      todayBtn: 'linked',
      todayHighlight: true,
      assumeNearbyYear: true,
      format: 'M, dd yyyy'
  }

  constructor(
    private _http: Http, 
    private _userDetails: UserDetailsService,
    private _httpService: HttpService,
    private fb: FormBuilder,
    private _uiRouter: UIRouter
    ) 
  { }

  ngOnInit() {
    this.filterfrm = this.fb.group({
      leagueType: ['ALL', []],
      sortBy:['',[]],
      startDate:['',[]],
      endDate:['',[]]
    });

    this.searchfrm = this.fb.group({
      contestName: ['', []],
    });

    this.contestListingData();
    // console.log('HttpService', this._httpService.getApiUrl('contestListing'));
  }

  sortDataFun(val){
    console.log(val);
    if(val.sortBy){
      this.sortBy = val.sortBy;
      this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.startDate?moment(val.startDate).format('YYYY/MM/DD')+" 00:00:00":'',val.endDate?moment(val.endDate).format('YYYY/MM/DD')+" 23:59:59":'');
    }
  }

  contestListingData(sortBy='',leagueType='ALL',contestType='ALL',startDate='',endDate=''){
    
    
    let listingData: any = {
            "leagueId":leagueType,
            "sortBy": this.sortBy,
            "startDate" : startDate?moment(startDate).format('YYYY/MM/DD')+" 00:00:00":'',
            "endDate" : endDate?moment(endDate).format('YYYY/MM/DD')+" 23:59:59":'',
            "currentPage":this.currentPage,
            "currentSize":this.itemsPerPage
    }

    this._httpService.httpRequest('POST','userListByName',listingData, true)
    .then(Response => {
      let data: any = Response['response'].data;
      this.numberOfItem = Response['response'].pagination.numberOfElements;
      this.totalPages = Response['response'].pagination.totalPages;
      this.collection = data;
      console.log('collection',this.collection);
    })
    .catch(data => {

    })
  }

  pageChanged($event,val){
    console.log(val);
    this.currentPage = $event;
    this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.startDate,val.endDate);
  }

  filterData(val){
    
    if((val.contestStartDate && !val.contestEndDate) || (!val.contestStartDate && val.contestEndDate)){
      this.dateSelectError = "Please select both start and end date"
    }else{
    this.contestListingData(val.sortBy,val.leagueType,val.contestType,val.startDate,val.endDate);
    }
  }

  resetFilter(){
    this.contestListingData();
  }

  startDateChange(date1){
    console.log('start date date 1');
    this.dateSelectError = "";
        if((this.date2<this.date1 && (this.date2))){
          this.dateSelectError = "End date should be greater than start date";
        }else{
          this.dateSelectError = "";
          this.datepickerToOpts = {
            startDate: this.date1,
            endDate : new Date(),
            autoclose: true,
            todayBtn: 'linked',
            todayHighlight: false,
            assumeNearbyYear: true,
            format: 'M, dd yyyy'
          }
        }
  }

  endDateChange(date1,date2){
    console.log('date1',date1);
    console.log('date2',date2);
    this.dateSelectError = "";
    if(date2 >= date1){
      this.dateSelectError = "";
    }else if((date2 && date1) && date2 <= date1){
      this.dateSelectError = "End date should be greater than start date";
    }
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { UIRouterModule } from '@uirouter/angular';
import { UIRouter } from '@uirouter/angular';
import { TransitionService } from '@uirouter/angular';
import { AlertModule } from 'ngx-bootstrap';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { UiSwitchModule } from '../../node_modules/angular2-ui-switch/src';

import { AppComponent } from './app.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ImageUploadModule } from 'angular2-image-upload';
//import { Moment } from 'moment';

// import { DateTimePickerDirective } from 'ng2-eonasdan-datetimepicker/src/datetimepicker.directive';
import 'eonasdan-bootstrap-datetimepicker';

// route
import { Routes } from './routes/routes';

// ngx pagination
import { NgxPaginationModule } from 'ngx-pagination';

// loader
import { LoadersCssModule } from 'angular2-loaders-css';

// date time module
import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime';

//import {CalendarModule} from 'primeng';
//import * as $ from 'jquery';
import * as $ from 'jquery'; window["$"] = $; window["jQuery"] = $;
// modal
import { ModalModule } from 'ngx-bootstrap/modal';
import { Moment } from 'moment/moment';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MomentModule } from 'angular2-moment';
import { A2Edatetimepicker } from 'ng2-eonasdan-datetimepicker';
// import { A2Edatetimepicker } from 'ng2-eonasdan-datetimepicker/dist/datetimepicker.module';
//import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
//import { Ng2UIModule }    from 'ng2-ui'; 


//html to pdf
import * as html2canvas from "html2canvas"
import jsPDF from 'jspdf'

// services
import { UserDetailsService } from './services/user-details.service';
import { HttpService } from './services/http.service';
//Pages 
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ContestListingComponent } from './contest-listing/contest-listing.component';
import { ContestOrderComponent } from './contest-order/contest-order.component';
import { ContestListingHistoryComponent } from './contest-listing-history/contest-listing-history.component';
import {ContestListingHistoryArchiveComponent} from './contest-listing-history-archive/contest-listing-history-archive.component'
import { ContestListingLiveComponent } from './contest-listing-live/contest-listing-live.component';
import { CreateContestComponent } from './create-contest/create-contest.component';
import { CreatePropComponent } from './create-prop/create-prop.component';

import { HouseUserContestUpcomingComponent } from './house-user-contest-upcoming/house-user-contest-upcoming.component'
import { HouseUserContestLiveComponent } from './house-user-contest-live/house-user-contest-live.component'
import { HouseUserContestHistoryComponent } from './house-user-contest-history/house-user-contest-history.component'
import { HousePropUpcomingComponent } from './house-prop-upcoming/house-prop-upcoming.component'
import { HousePropLiveComponent } from './house-prop-live/house-prop-live.component'
import { HousePropHistoryComponent } from './house-prop-history/house-prop-history.component'
import { SinglePropLiveComponent } from './single-prop-live/single-prop-live.component';
import { SinglePropHistoryComponent } from './single-prop-history/single-prop-history.component'
import { CreateSimilarComponent } from './create-similar/create-similar.component';
import { TeamListingComponent } from './team-listing/team-listing.component';
import { TeamListingPropComponent } from './team-listing-prop/team-listing-prop.component';
import { TeamListingVersusComponent } from './team-listing-versus/team-listing-versus.component';
import { PropsModalComponent } from './props-modal/props-modal.component';

import { PropsModalSingleComponent } from './props-modal-single/props-modal-single.component';
import { PropsModalVersusComponent } from './props-modal-versus/props-modal-versus.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PricePayoutComponent } from './price-payout/price-payout.component';
import { ListOfEntrantsComponent } from './list-of-entrants/list-of-entrants.component';
import { EntrantDetailModalComponent } from './entrant-detail-modal/entrant-detail-modal.component';
import { ContestDetailComponent } from './contest-detail/contest-detail.component';
import { RegisteredFansComponent } from './registered-fans/registered-fans.component';
import { ListOfContestsComponent } from './list-of-contests/list-of-contests.component';
import { FanDetailComponent } from './fan-detail/fan-detail.component';
import { FirstTimeDepositComponent } from './first-time-deposit/first-time-deposit.component';
import { ListOfContestModalComponent } from './list-of-contest-modal/list-of-contest-modal.component';
import { ContentComponent } from './content/content.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { PopularPlayersComponent } from './popular-players/popular-players.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { EditContestComponent } from './edit-contest/edit-contest.component';
import { AverageGameTimeComponent } from './average-game-time/average-game-time.component';
import { DatePipe } from './services/date-pipe';
import { PrizePayoutComponent } from './prize-payout/prize-payout.component';
import { LivePlayerStatsComponent } from './live-player-stats/live-player-stats.component';
import { ContestPlayersComponent } from './contest-players/contest-players.component';
import { PlayerPropsComponent } from './player-props/player-props.component';
import { LiveStatsModalComponent } from './live-stats-modal/live-stats-modal.component';
import { PricePayoutModalComponent } from './price-payout-modal/price-payout-modal.component';
import { NewUsersComponent } from './new-users/new-users.component';
import { ContestTicketsComponent } from './contest-tickets/contest-tickets.component';
import { HouseTicketsComponent } from './house-tickets/house-tickets.component';
import { CreateATicketModalComponent } from './create-a-ticket-modal/create-a-ticket-modal.component';
import { CreateAHouseTicketModalComponent } from './create-a-house-ticket-modal/create-a-house-ticket-modal.component';
import { AssignTicketsModalComponent } from './assign-tickets-modal/assign-tickets-modal.component';
import { AssignHouseTicketsModalComponent } from './assign-house-tickets-modal/assign-house-tickets-modal.component';
import { UserTicketsByContestTicketsModalComponent } from './user-tickets-by-contest-tickets-modal/user-tickets-by-contest-tickets-modal.component';
import { UserTicketsByHouseTicketsModalComponent } from './user-tickets-by-house-tickets-modal/user-tickets-by-house-tickets-modal.component';


import { LandingPageComponent } from './landing-pages/landing-pages.component';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { ExcludedUsersComponent } from "./excluded-users/excluded-users.component";
import {CreateAUserUserRoleModalComponent} from "./create-a-user-user-role-modal/create-a-user-user-role-modal.component";
import {ListOfEntrantsArchiveComponent} from "./list-of-entrants-archive/list-of-entrants-archive.component";
import {HeadshotsComponent} from "./headshots/headshots.component";
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    AppComponent,
    // DateTimePickerDirective,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    ContestListingComponent,
    ContestOrderComponent,
    ContestListingHistoryComponent,
    ContestListingHistoryArchiveComponent,
    ContestListingLiveComponent,
    CreateContestComponent,
    CreatePropComponent,
    CreateSimilarComponent,
    TeamListingComponent,
    TeamListingPropComponent,
    TeamListingVersusComponent,
    PropsModalComponent,
    PropsModalVersusComponent,
    PropsModalSingleComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    PricePayoutComponent,
    PropsModalSingleComponent,
    ListOfEntrantsComponent,
    ListOfEntrantsArchiveComponent,
    EntrantDetailModalComponent,
    ContestDetailComponent,
    RegisteredFansComponent,
    ListOfContestsComponent,
    FanDetailComponent,
    FirstTimeDepositComponent,
    ListOfContestModalComponent,
    ContentComponent,
    AnalyticsComponent,
    PopularPlayersComponent,
    SendNotificationComponent,
    EditContestComponent,
    AverageGameTimeComponent,
    DatePipe,
    PrizePayoutComponent,
    LivePlayerStatsComponent,
    ContestPlayersComponent,
    PlayerPropsComponent,
    LiveStatsModalComponent,
    PricePayoutModalComponent,
    HouseUserContestUpcomingComponent,
    HouseUserContestLiveComponent,
    HouseUserContestHistoryComponent,
    HousePropUpcomingComponent,
    HousePropLiveComponent,
    HousePropHistoryComponent,
    SinglePropLiveComponent,
    SinglePropHistoryComponent,
    NewUsersComponent,
    ContestTicketsComponent,
    CreateATicketModalComponent,
    AssignTicketsModalComponent,
    UserTicketsByContestTicketsModalComponent,
    UserTicketsByHouseTicketsModalComponent,
    HouseTicketsComponent,
    CreateAHouseTicketModalComponent,
    AssignHouseTicketsModalComponent,
    LandingPageComponent,
    PromoCodeComponent,
    ExcludedUsersComponent,
    CreateAUserUserRoleModalComponent,
    HeadshotsComponent
  ],
  imports: [

    MomentModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    UiSwitchModule,
    FileUploadModule,
    // Ng2UIModule,
    //Ng2AutoCompleteModule.forRoot(),
    UIRouterModule.forRoot({
      states: Routes,
      otherwise: {state: 'login'}
    }),
    ReactiveFormsModule,
    NKDatetimeModule,
    NgxPaginationModule,
    LoadersCssModule,
    //CalendarModule,
    A2Edatetimepicker,
    ModalModule.forRoot(),
    ImageUploadModule.forRoot(),
    DragDropModule,
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, UserDetailsService, HttpService],
  bootstrap: [AppComponent]
})
export class AppModule { }
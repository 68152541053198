import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { StateService, UIRouter } from '@uirouter/angular';
import { SERVER_NAME, APIS } from '../config';
import { HttpService } from '../services/http.service';
import { UserDetailsService } from '../services/user-details.service';
import { DatePipe } from '@angular/common';


// modal
import { PropsModalComponent } from '../props-modal/props-modal.component';
declare const $:any;
@Component({
  selector: 'app-team-listing',
  templateUrl: './team-listing.component.html',
  styleUrls: ['./team-listing.component.scss'],
  providers: [HttpService,DatePipe]
})
export class TeamListingComponent implements OnInit {
  teamlistForm: FormGroup;
  startTime: any;
  endTime:any;
  featured: any;
  leagueType: any;
  leagueDate : any;
  eventsData : any;
  playersData : any;
  teamName : any;
  golfRound : any;
  eventPropsdata : any;
  noDataMsg:string;
  adminContestId : any;
  teamid : any;
  createdPropsData : any;
  createdPropsPlayerArr = [];
  createdPropsTeamArr = [];
  totalPlayerSelected:number;
  minmumPropToPick:any;
  eventDateToShow : any;

  @ViewChild('propModal') propModal : PropsModalComponent;

  constructor(private _httpService: HttpService, 
  private fb: FormBuilder, 
  private _uiRouter: UIRouter,
  private _userDetailsService: UserDetailsService,
  private datePipe : DatePipe) {

    //console.log(this._uiRouter.stateService.params.adminContestId);
    // this.contestId = this._uiRouter.stateService.params['id'];
    this.leagueType = this._uiRouter.stateService.params.leagueType;
    //console.warn(this._uiRouter.stateService.params);
    this.startTime = this._uiRouter.stateService.params.startTime;
    this.endTime = this._uiRouter.stateService.params.endTime;
    this.eventDateToShow = this.datePipe.transform(this.startTime,"MMM dd, yyyy")


    this.adminContestId = this._uiRouter.stateService.params.adminContestId;

    this.leagueDate = this.startTime;
    //console.log('league type', this.leagueType);
    //console.log('start time', this.startTime);
    var arr;
   }

  ngOnInit() {
    this.teamlistForm = this.fb.group({
      pl1: ['', []],
      pl2: ['', []],
      pl3: ['', []]

    })

    let requestData = {
      "adminContestId":this.adminContestId,"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"startDate":this.startTime,"endDate":this.endTime
    }

    // ************************** Event Liting *************************
    this._httpService.httpRequest('POST', 'eventsListing',requestData, true)
      .then(Response => {
        let responseData :any = Response;
        if(responseData.response.data.length > 0){
          this.leagueType = responseData.response.data[0].leagueType;
          this.leagueDate = this.startTime;
          this.eventsData = responseData.response.data;
          console.log("=====----- ",this.eventsData)
          for(let data of this.eventsData) {
              console.log("**** "+data.eventId+" - "+data.sportradarMatchId+" - "+data.team1Name+" vs "+data.team2Name + " - " + data.slgMatchId);
              console.log(data)
          }
        }else{
          this.noDataMsg = "No event is scheduled on this date.";
        }
      })
      .catch(data => {
        // console.log('data message',data);
        this.noDataMsg = data.message;
    })
    // ************************** Event Liting *************************
    this.fetchProps();
    
    //console.log("coming===="+window.localStorage.getItem("teamid"));
    if(window.localStorage.getItem("teamid")){
      //console.log("coming222===="+window.localStorage.getItem("teamname"));
      //console.log("coming222===="+window.localStorage.getItem("playersData"));
      //this.getTeamPlayer(window.localStorage.getItem("teamid"),window.localStorage.getItem("teamname"),window.localStorage.getItem("eventindex"));
      this.playersData = JSON.parse(window.localStorage.getItem("playersData"));
      this.teamName = window.localStorage.getItem("teamname");
      this.teamid = window.localStorage.getItem("teamid");
      this.eventPropsdata = JSON.parse(window.localStorage.getItem("eventPropsdata"));
  }
    //console.log('propModal',this.propModal)

     $('#propModal').on('hide', function () {
      console.log("into props modal");
    });

    $('#propModal').on('hidden', function () {
      console.log("into props modal 2");
    });
  }


removeProp(index,playerId){

    
    this._httpService.httpRequest('DELETE', 'deleteProp',"propId="+index, true)
      .then(Response => {
        //let responseData :any = Response;
        //let pidtoremove = this.createdPropsArr.indexOf(playerId);
        //this.createdPropsArr.slice(pidtoremove,1);
        //console.log(playerId);
        //console.log(this.createdPropsArr);
        this._uiRouter.stateService.reload();
        this.fetchProps();
      })
      .catch(data => {
        console.log('remove prop error', data);
    })
}

getTeamPlayer(teamid,sportradarTeamId,pandascoreTeamId,abiosTeamId,eventId,teamname,eventIndex, slgTeamId){

  this.eventPropsdata = this.eventsData[eventIndex];
  this.teamid= teamid;
  console.log("------calling getTeamPlayerNormal teamId: ",teamid," sportradarTeamId: ",sportradarTeamId, "slg_team_id: " + slgTeamId ," pandascoreTeamId: ",pandascoreTeamId," abiosTeamId: ",abiosTeamId," league: ",this.leagueType);

  if(this.leagueType == "NFL" || this.leagueType == "MLB" || this.leagueType == "NBA" || this.leagueType == "SOCCER" || this.leagueType == "AUDL" || this.leagueType == "CRICKET" || this.leagueType == "HOCKEY" || this.leagueType == "UFC"){
    this._httpService.httpRequest('POST', 'playerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"teamId":teamid,"eventId":eventId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "CSGO2" || this.leagueType == "LOL" || this.leagueType == "DOTA2"){
    this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"sportsRadarTeamId":sportradarTeamId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "OW"){
    this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"pandascoreTeamId":pandascoreTeamId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "GOLF"){
    this._httpService.httpRequest('POST', 'golfPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":200,"sportsRadarTeamId":sportradarTeamId,"golfRound":this.eventPropsdata.golfRound,"eventId":eventId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.golfRound = "0";
        if (responseData.response.data[0] != null){
          this.golfRound = responseData.response.data[0].golfRound;
        }
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "VAL"){
    this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"abiosTeamId":abiosTeamId}, true)
      .then(Response => {

        let responseData :any = Response;
        this.teamName = teamname;
        this.playersData = responseData.response.data;
        //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
        window.localStorage.setItem("teamid",teamid);
        window.localStorage.setItem("teamname",teamname);
        window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
        window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
      })
      .catch(data => {
        console.log('team list error', data);
        this.noDataMsg = "No record found";
    })
  }else if(this.leagueType == "SLG_CSGO"){
      console.log(teamid)
      this._httpService.httpRequest('POST', 'esportsPlayerListing',{"leagueType":this.leagueType,"currentPage":1,"currentSize":100,"slgTeamId":slgTeamId}, true)
          .then(Response => {
              let responseData :any = Response;
              this.teamName = teamname;
              this.playersData = responseData.response.data;
              //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
              window.localStorage.setItem("teamid",teamid);
              window.localStorage.setItem("teamname",teamname);
              window.localStorage.setItem("playersData",JSON.stringify(this.playersData));
              window.localStorage.setItem("eventPropsdata",JSON.stringify(this.eventPropsdata));
          })
          .catch(data => {
              console.log('team list error', data);
              this.noDataMsg = "No record found";
          })
  } else if(this.leagueType == "LOTTERY") {
      this._httpService.httpRequest('POST', 'lotteryPlayerListing', {
          "leagueType": this.leagueType,
          "currentPage": 1,
          "currentSize": 200,
          "eventId": eventId
      }, true)
          .then(Response => {

              let responseData: any = Response;
              this.teamName = teamname;
              this.golfRound = "0";
              if (responseData.response.data[0] != null) {
                  this.golfRound = responseData.response.data[0].golfRound;
              }
              this.playersData = responseData.response.data;
              //this.propsPostData = {"contestTradational":true,"event":this.eventsData[eventIndex]};
              window.localStorage.setItem("teamid", teamid);
              window.localStorage.setItem("teamname", teamname);
              window.localStorage.setItem("playersData", JSON.stringify(this.playersData));
              window.localStorage.setItem("eventPropsdata", JSON.stringify(this.eventPropsdata));
          })
          .catch(data => {
              console.log('team list error', data);
              this.noDataMsg = "No record found";
          })
    }
}


fetchProps(){
  console.log("fetch Props 2=======")
  // ************************** Contest Liting *************************
    this._httpService.httpRequest('GET', 'fetchProps',"adminContestId="+this.adminContestId, true)
      .then(Response => {
        let responseData :any = Response;

        this.createdPropsData = responseData.response.adminContestProp;
        this.totalPlayerSelected = responseData.response?responseData.response.adminContestProp.length:0;
        this.minmumPropToPick = responseData.response?(responseData.response.minmumPropToPick+2):0;
        for(let data of this.createdPropsData) {
          this.createdPropsPlayerArr.push(data.player1Id);
          this.createdPropsTeamArr.push(data.player1TeamId);
        }
      })
      .catch(data => {
        //console.log('Event list error', data);
        //this.noDataMsg = data.message;
    })
    // ************************** Contest Liting *************************
}


publishContest(){
  // ************************** Contest Liting *************************
    this._httpService.httpRequest('GET', 'publishContest',"contestid="+this.adminContestId, true)
      .then(Response => {
        let responseData :any = Response;
        if(responseData.response){
            this._userDetailsService.showSuccess(responseData.message);
            this._uiRouter.stateService.go('contest-listing');
        }else{
          this._userDetailsService.showError(responseData.message);
        }
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
    })
    // ************************** Contest Liting *************************
}

hideModal(){
  alert("coming")
}

 

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../services/http.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { UserDetailsService } from '../services/user-details.service';
import { DatePipe } from '../services/date-pipe';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SERVER_NAME } from '../config';
import { Moment } from 'moment';
import { PricePayoutModalComponent } from '../price-payout-modal/price-payout-modal.component';

declare const $: any;

@Component({
  selector: 'app-prize-payout',
  templateUrl: './prize-payout.component.html',
  styleUrls: ['./prize-payout.component.scss']
})
export class PrizePayoutComponent implements OnInit {

  @ViewChild('prizePayout') prizePayout: ModalDirective;
  @ViewChild('checkPayout') checkPayout: ModalDirective;

  filterForm: FormGroup;
  responsePayout: any;
  prizePayoutUsers: any;
  fanDetail: any;
  pagination: any;
  showFanDetail: boolean = false;
  currentPage: number = 1;
  pageSize: number = 10;
  userName: any = "";
  sortByNoOfContestsParicipated: boolean = false;
  sortByTotalWonPrizeMoney: boolean = false;
  isDescreasingOrder: boolean = false;
  noData: boolean = false;

  constructor(private httpService: HttpService,
    private fb: FormBuilder,
    private _uiRouter: UIRouter,
    private _userDetailsService: UserDetailsService
  ) { }

  ngOnInit() {
    debugger;
    this.prizePayoutList();
    // this.filterForm = this.fb.group({
    //   sortBy: ['noOfContestParticipated',[]],
    //   order: ['increasing',[]]
    // })
  }

  prizePayoutList() {
    this.noData = false;
    let requestPayload: any = {
      "currentPage": this.currentPage,
      "currentSize": this.pageSize
    }

    this.httpService.httpRequest('POST', 'prizePayout', requestPayload, false)
      .then(Response => {
        this.noData = false;
        let response: any = Response;
        this.pagination = response.response.pagination;
        this.prizePayoutUsers = response.response.data;

        console.log(this.prizePayoutUsers)

        if (this.prizePayoutUsers == "") {
          this.noData = true;
        }
      })
      .catch(data => {
        console.log('error', data);
      })
  }

  payByCheck() {
    console.log("pay by check");
    this.checkPayout.show();
    //$('#checkPayoutSection').html();

  }

  paymentSessionPayout(uid, amount, payoutId) {
    //console.log(SERVER_NAME+"/payment/callback-payout");
    let requestPayload = {
      "MerchantCustomerID": "MCID_aCmqmTuqoUO8FXGe39Mrzr",
      "PayActionCode": "PAYOUT",
      "userId": uid,
      "payoutId": payoutId,
      "CashierPaymentAmount": {
        "PaymentAmount": amount,
        "PaymentAmountOverride": true,
        "PaymentCurrencyCode": "USD"
      },
      "CallbackURL": SERVER_NAME + "/payment/callback-payout"
    };
    this.httpService.httpRequest('POST', 'paymentSessionPayout', requestPayload, false)
      .then(Response => {
        let response: any = Response;
        //this._userDetailsService.showSuccess(response.message);
        //Consumed another api for script 
        this.httpService.httpRequest('POST', 'scriptTagPayout', { "scriptTag": response.response.SessionURL, "sessionId": response.response.MerchantSessionID }, false)
          .then(Response => {
            let responseData: any = Response;
            this.prizePayout.show();

            responseData.response.suffix = responseData.response.suffix.replace("sessionId", "\"" + responseData.response.merchantSessionId + "\"");
            responseData.response.suffix = responseData.response.suffix.replace("transactionId", "\"" + responseData.response.merchantTransactionId + "\"");
            responseData.response.suffix = responseData.response.suffix.replace("token", "\"" + this._userDetailsService.getAccessToken() + "\"");


            let htmlContent = responseData.response.prefix + responseData.response.scriptTag + responseData.response.suffix;
            console.log(htmlContent);
            $('#prizePayoutSection').html(htmlContent);
          })
          .catch(data => {
            this._userDetailsService.showError(data.message);
          })
        //Consumed another api for script
      })
      .catch(data => {
        this._userDetailsService.showError(data.message);
      })
  }

  pageChanged($event, val) {
    this.currentPage = $event;
    this.prizePayoutList();
  }

  /*searchFanFun(searchChar){
    console.log('char',searchChar);
    searchChar = $.trim(searchChar);
    this.userName = searchChar;
    this.prizePayoutList();
  }

  contestFilterFun(value){
    if(value.sortBy == "noOfContestParticipated"){
      this.sortByNoOfContestsParicipated = true;
      this.sortByTotalWonPrizeMoney = false;
      if(value.order == "decreasing"){
        this.isDescreasingOrder = true;
        this.prizePayoutList();
        console.log('decreasing');
      }else{
        this.isDescreasingOrder = false;
        this.prizePayoutList();
        console.log('increasing');
      }
    }else{
      this.sortByTotalWonPrizeMoney = true;
      this.sortByNoOfContestsParicipated = false;
      this.prizePayoutList();
      if(value.order == "decreasing"){
        this.isDescreasingOrder = true;
        this.prizePayoutList();
        console.log('decreasing');
      }else{
        this.isDescreasingOrder = false;
        this.prizePayoutList();
        console.log('increasing');
      }
    }

  }*/

  hide() {
    this._uiRouter.stateService.reload();
  }

  prizeoutAccept(requestId) {
    if (confirm("Are you sure you want to accept this request?")) {
      console.log(requestId)

      let body = {
        "request_id": requestId
      }

      let requestData = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "env": "sandbox",
          "secret_key": "ed3af91c063d1e2305992ff4dd5e6132",
          "partner_id": "d093e802-21bf-4504-9733-e245570a0b4c",
          "api_key": "3f9479d28dc24a78d59e85d1eca256ea"
        },
        body: JSON.stringify(body)
      }

      fetch('https://us-east1-prizeout.cloudfunctions.net/prod-api/request/accept', requestData)
        .then(response => response.json())
        .then(data => {
          if (data['success']) {
            console.log(data['response'])
            $('.loader').removeClass('show');
          } else {
            // messages('error', data['message']);
            $('.loader').removeClass('show');
          }
        });
    }
  }

  prizeoutReject(requestId) {
    if (confirm("Are you sure you want to reject this request?")) {
      let body = {
        "request_id": requestId
      }

      let requestData = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "env": "sandbox",
          "secret_key": "ed3af91c063d1e2305992ff4dd5e6132",
          "partner_id": "d093e802-21bf-4504-9733-e245570a0b4c",
          "api_key": "3f9479d28dc24a78d59e85d1eca256ea"
        },
        body: JSON.stringify(body)
      }
      fetch('https://us-east1-prizeout.cloudfunctions.net/prod-api/request/reject', requestData)
        .then(response => response.json())
        .then(data => {
          if (data['success']) {
            console.log(data['response'])
            $('.loader').removeClass('show');
          } else {
            // messages('error', data['message']);
            $('.loader').removeClass('show');
          }
        });
    }
  }

}
